/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styles from './banner.module.scss';

const Blurhash = dynamic(() =>
   import(/* webpackChunkName: "blurhash" */ '../../../../components/Blurhash'),
);

function BannerItem(props) {
   const {
      url,
      desktopImage,
      mobileImage,
      desktopSize,
      mobileSize,
      alt,
      className,
      preload,
      mobileQuality,
      desktopQuality,
   } = props;

   const [bannerLoaded, setBannerLoaded] = useState(false);

   const isDesktop = useMediaQuery('(min-width: 768px)');

   const classBanner = cn({
      [styles.container]: true,
      [className]: !!className,
   });

   const classBlur = cn({
      [styles.blur]: true,
      [styles['blur--hidden']]: bannerLoaded,
   });

   const onLoadedData = () => {
      setBannerLoaded(true);
   };

   return (
      <Link href={url}>
         <a className={classBanner}>
            <div className={styles.mobile}>
               {!['webm', 'mp4', 'gif'].includes(desktopImage.ext) ? (
                  <Image
                     ext={mobileImage.ext}
                     src={mobileImage.url}
                     width={mobileSize.width}
                     height={mobileSize.height}
                     alt={alt}
                     layout="responsive"
                     priority={preload}
                     quality={mobileQuality}
                     onLoad={onLoadedData}
                  />
               ) : (
                  <video
                     width="100%"
                     height="100%"
                     onLoadedData={onLoadedData}
                     loop
                     autoPlay
                     muted
                     playsInline
                  >
                     <source
                        src={mobileImage.url}
                        type='video/webm; codecs="vp8, vorbis"'
                        onPlaying={onLoadedData}
                     />
                  </video>
               )}
            </div>

            <div className={styles.desktop}>
               {!['webm', 'mp4', 'gif'].includes(desktopImage.ext) ? (
                  <Image
                     ext={desktopImage.ext}
                     src={desktopImage.url}
                     width={desktopSize.width}
                     height={desktopSize.height}
                     alt={alt}
                     layout="responsive"
                     quality={desktopQuality}
                     onLoad={onLoadedData}
                  />
               ) : (
                  <video
                     loop
                     muted
                     autoPlay
                     height="100%"
                     width="100%"
                     playsInline
                     onLoadedData={onLoadedData}
                  >
                     <source
                        src={desktopImage.url}
                        type='video/webm; codecs="vp8, vorbis"'
                        onPlaying={onLoadedData}
                     />
                  </video>
               )}
            </div>

            {!preload && !bannerLoaded && (
               <div className={classBlur}>
                  <Blurhash
                     hash={isDesktop ? mobileImage.blurhash : desktopImage.blurhash}
                     height="100%"
                     width="100%"
                  />
               </div>
            )}
         </a>
      </Link>
   );
}

export default BannerItem;

BannerItem.propTypes = {
   preload: PropTypes.bool,
   mobileQuality: PropTypes.number,
   desktopQuality: PropTypes.number,
};

BannerItem.defaultProps = {
   preload: false,
   mobileQuality: 25,
   desktopQuality: 50,
};
